*
{
margin: 0;
padding:0; 
}
.App
{
  /* min-height: 100vh; */
  display: flex;
  flex-direction: column;
}


footer{
        
  width: 100%;
  position: static;
 
}


body{
  background-color: rgb(169, 169, 167);
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
}

@font-face {
  font-family: Steiner;
  src: url(./assets/Steinerlight.ttf);
}
@media screen 
    and (min-width: 374px) 

    {


      footer{
        
        width: 100%;
        position: relative;
       
      }
      



    }