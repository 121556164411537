@media screen 
    and (min-width: 360px) 
    and (max-width: 500px)
 {
        .servMain{
            height: auto;
            width: 375px;
            position: relative;
            display: block;
            /* padding-bottom: 30vh; */
            overflow: hidden;
        
        }
        .servHeader{
            padding-top: 15px;
            margin-left: 15px;
            font-family: Steiner;
            font-weight: bolder;
            font-size: 8vw;
        }
        .rateHeader{
            padding-top: 15px;
            margin-left: 15px;
            font-family: Steiner;
            font-weight: bolder;
            font-size: 7vw;

        }
        .mechImage{
       visibility: hidden;
       display:none;
      
        }
        
        #servicon{
            width:7.5vw;
            padding-right: 5px;
        }
    
        #mTitle{
            list-style: outside none none;
            font-family: Arial, Helvetica, sans-serif;
            font-weight:bolder;
            color: rgb(164, 10, 10);
            font-size: 13pt;
        }
        #serTitle{
            font-family: Arial, Helvetica, sans-serif;
            font-weight: bold;
            font-size: 11pt;
        }
        #servDesc{
            font-family: Arial, Helvetica, sans-serif;
            font-weight: normal;
            font-size: 9pt;
            padding-left: 30px;
        }
        .mainList{
            margin-left:20px;
        }
        hr{
            margin-left: 15px;
            margin-right: 440px;
            border-width: 3px;
        }
        .prevServ{
            margin-left: 15px;
        }
        .prevList{
            margin-left: 15px;
        }
     
            
        
        #servTitle{
            font-family: Arial, Helvetica, sans-serif;
            font-weight:bold;
        }
        
        #genState{
            margin-left: 15px;
            font-family: 'Kanit', sans-serif;
            font-size: 5vw;
        
        }
        #collapseHeaders{
            font-size:5vw;
        }
        .card-header{
            width:100%
        }

    }
    @media screen 
    and (min-width: 500px) 
    and (max-width: 599.99px){
        .servMain{
            height: auto;
            width: 375px;
            position: relative;
            display: block;
            /* padding-bottom: 30vh; */
            overflow: hidden;
        
        }
        .servHeader{
            padding-top: 15px;
            margin-left: 15px;
            font-family: Steiner;
            font-weight: bolder;
            font-size: 25pt;
        }
        .rateHeader{
            padding-top: 15px;
            margin-left: 15px;
            font-family: Steiner;
            font-weight: bolder;
            font-size: 7vw;

        }
        .mechImage{
       visibility: hidden;
       display:none;
       /* margin-bottom: 80px; */
        }
        
        
        .mainList{
            margin-left:20px;
        }
        hr{
            margin-left: 15px;
            margin-right: 440px;
            border-width: 3px;
        }
        .prevServ{
            margin-left: 15px;
        }
        .prevList{
            margin-left: 15px;
        }
        #servicon{
            width:6vw;
            padding-right: 5px;
        }
    
        #mTitle{
            list-style: outside none none;
            font-family: Arial, Helvetica, sans-serif;
            font-weight:bolder;
            color: rgb(164, 10, 10);
            font-size: 2.8vw;
        }
        #serTitle{
            font-family: Arial, Helvetica, sans-serif;
            font-weight: bold;
            font-size:2.5vw;
        }
        #servDesc{
            font-family: Arial, Helvetica, sans-serif;
            font-weight: normal;
            font-size:2vw;
            padding-left: 30px;
        }
        
        #genState{
            margin-left: 15px;
            font-family: 'Kanit', sans-serif;
            font-size: 17pt;
        
        }
        #collapseHeaders{
            font-size:17pt;
        }
     

    }

@media screen and (min-width: 600px) and (max-width:992px){
   
    .servHeader{
        padding-top: 15px;
        margin-left: 15px;
        font-family: Steiner;
        font-weight: bolder;
        font-size: 4vw;
    }
    .rateHeader{
        padding-top: 15px;
        margin-left: 15px;
        font-family: Steiner;
        font-weight: bolder;
        font-size: 7vw;

    }
    
    .mechImage{
    float: right;
    width: 300px;
    height: 250px;
    margin-right: 30px;
   
    }
    
    #servicon{
        width:5vw;
        padding-right: 5px;
    }

    #mTitle{
        list-style: outside none none;
        font-family: Arial, Helvetica, sans-serif;
        font-weight:bolder;
        color: rgb(164, 10, 10);
        font-size: 2.5vw;
    }
    #serTitle{
        font-family: Arial, Helvetica, sans-serif;
        font-weight: bold;
        font-size:2vw;
    }
    #servDesc{
        font-family: Arial, Helvetica, sans-serif;
        font-weight: normal;
        font-size:1.5vw;
        padding-left: 30px;
    }
    .mainList{
        margin-left:20px;
    }
    hr{
        margin-left: 15px;
        margin-right: 440px;
        border-width: 3px;
    }
    .prevServ{
        margin-left: 15px;
    }
    .prevList{
        margin-left: 15px;
    }
    #servDesc{
        font-family: Arial, Helvetica, sans-serif;
        font-weight: normal;
    }
    #servTitle{
        font-family: Arial, Helvetica, sans-serif;
        font-weight:bold;
    }
    
    #genState{
        margin-left: 15px;
        font-family: 'Kanit', sans-serif;
        font-size: 3vw;
    
    }
    #collapseHeaders{
        font-size:2.5vw;
    }

}


@media screen and (min-width: 992px) and (max-width:1200px){
.servMain{
    background-color: rgb(169, 169, 167);
    background-position: center;
    background-repeat: no-repeat;
    margin:0 50px 0 50px;
    height: 100%;
    width: auto;
    
}
.rateHeader{
    padding-top: 15px;
    margin-left: 15px;
    font-family: Steiner;
    font-weight: bolder;
    font-size: 22pt;

}
.servHeader{
    padding-top: 15px;
    margin-left: 15px;
    font-family: Steiner;
    font-weight: bolder;
    font-size: 28pt;
}

.mechImage{
float: right;
margin-right: 30px;

}

#servicon{
    width:5vw;
    padding-right: 5px;
}

#mTitle{
    list-style: outside none none;
    font-family: Arial, Helvetica, sans-serif;
    font-weight:bolder;
    color: rgb(164, 10, 10);
    font-size: 20pt;
}
#serTitle{
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
    font-size:18pt;
}
#servDesc{
    font-family: Arial, Helvetica, sans-serif;
    font-weight: normal;
    font-size:16pt;
    padding-left: 50px;
}
.mainList{
    margin-left:20px;
}
hr{
    margin-left: 15px;
    margin-right: 440px;
    border-width: 3px;
}
.prevServ{
    margin-left: 15px;
}
.prevList{
    margin-left: 15px;
}

#servTitle{
    font-family: Arial, Helvetica, sans-serif;
    font-weight:bold;
}

#genState{
    margin-left: 15px;
    font-family: 'Kanit', sans-serif;

}

}

@media screen and (min-width:1200px){
    .servMain{
  
  
        height: 100%;
        width: auto;
        padding-bottom: 60px;
        
    }
    .servHeader{
        padding-top: 15px;
        margin-left: 15px;
        font-family: Steiner;
        font-weight: bolder;
        font-size: 28pt;
    }
    .rateHeader{
        padding-top: 15px;
        margin-left: 15px;
        font-family: Steiner;
        font-weight: bolder;
        font-size: 22pt;
    }
    
    .mechImage{
    float: right;
    margin-right: 30px;

    }
    
    #servicon{
        width:45pt;
        padding-right: 5px;
    }
    
    #mTitle{
        list-style: outside none none;
        font-family: Arial, Helvetica, sans-serif;
        font-weight:bolder;
        color: rgb(164, 10, 10);
        font-size: 22pt;
    }
    #serTitle{
        font-family: Arial, Helvetica, sans-serif;
        font-weight: bold;
        font-size:18pt;
    }
    #servDesc{
        font-family: Arial, Helvetica, sans-serif;
        font-weight: normal;
        font-size:14pt;
        padding-left: 65px;
    }
    .mainList{
        margin-left:20px;
    }
    hr{
        margin-left: 15px;
        margin-right: 440px;
        border-width: 3px;
    }
    .prevServ{
        margin-left: 15px;
    }
    .prevList{
        margin-left: 15px;
    }
    #servDesc{
        font-family: Arial, Helvetica, sans-serif;
        font-weight: normal;
    }
    #servTitle{
        font-family: Arial, Helvetica, sans-serif;
        font-weight:bold;
    }
    
    #genState{
        margin-left: 15px;
        font-family: 'Kanit', sans-serif;
    
    }
    }
    @media screen and (max-height:800px){
        
        .mechImage{
            float: right;
            margin-right: 30px;
            
            }

    }

    @media screen and (min-height:1200px){

        .servMain{
  
  
            height: 100%;
            width: auto;
            padding-bottom: 16vh ;
            
        }
    }
    
   
        
    