/* 2436x1125px at 458ppi */

@media  screen 
and (min-width: 360px) 
and (max-width: 500px)
  {

        nav{
            background-color: #595959;
            
    
        }
        .logo{
            width: 300px;
            height:83px;
            padding:10px 0 0 40px;
            margin-top: 10px;
            margin-left:15px;
        }
        .links{ 
        float: center;
        list-style: none;
        padding-left: 7px;
    
    
    
        /* identical to box height */
    
    
    
        }
        #navli{
        padding:0 15px 0  0;
        display: inline;
        text-decoration: none;
        font-family: Steiner;
        font-style: normal;
        font-weight: thin;
        font-size: 10pt;
        line-height: 31px;
        color: #F2F2F2;
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        }
        Link{
            text-decoration: none;
        }
        a{
            color:inherit;
    
        }
    
    
        li :hover{
            color: black;
        }
    



     }

     @media screen and  (min-width: 500px) and (max-width: 566px){
        
        nav{
            background-color: #595959;
    
        }
        .logo{
            width: 300px;
            height:83px;
            padding: 10px 0 0 40px;
            margin-top: 15px;
        }
        .links{ 
        float: center;
        padding-left: 50px;
        list-style: none;
    
    
    
        /* identical to box height */
    
    
    
        }
        #navli{
        padding:0 15px 0  0;
        display: inline;
        text-decoration: none;
        font-family: Steiner;
        font-style: normal;
        font-weight: thin;
        font-size: 2vw;
        line-height: 31px;
        color: #F2F2F2;
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        }
        Link{
            text-decoration: none;
        }
        a{
            color:inherit;
    
        }
    
    
        li :hover{
            color: black;
        }
     }
    

@media screen and (min-width: 567px ) and (max-width: 700px){
    nav{
        background-color: #595959;
        

    }
    .logo{
        width: 300px;
        height:83px;
        padding: 10px 0 0 40px;
        margin-top: 15px;
    }
    .links{ 
    float: center;
    padding:20px 40px 15px 8vw;
    list-style: none;



    /* identical to box height */



    }
    #navli{
    padding:0 15px 0  0;
    display: inline;
    text-decoration: none;
    font-family: Steiner;
    font-style: normal;
    font-weight: thin;
    font-size: 2vw;
    line-height: 31px;
    color: #F2F2F2;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }
    Link{
        text-decoration: none;
    }
    a{
        color:inherit;

    }


    li :hover{
        color: black;
    }

}


@media screen and (min-width: 701px ) and (max-width: 1076px){
    nav{
        background-color: #595959;
       
        

    }
    .logo{
        width: 275px;
        height:84px;
        padding: 10px 0 0 40px;
        margin-top: 15px;
    }
    .links{ 
    float: right;
    padding:50px 40px 15px 0;
    list-style: none;



    /* identical to box height */



    }
    #navli{
    padding:0 15px 0  0;
    display: inline;
    text-decoration: none;
    font-family: Steiner;
    font-style: normal;
    font-weight: thin;
    font-size: 2vw;
    line-height: 31px;
    color: #F2F2F2;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }
    Link{
        text-decoration: none;
    }
    a{
        color:inherit;

    }


    li :hover{
        color: black;
    }
}

@media screen and (min-width: 1077px) and (max-width: 1200px) {

    nav{
        background-color: #595959;
     

    }
    .logo{
        width: 375px;
        height:110.42px;
        padding: 10px 0 0 70px;
        
    }
    .links{ 
    float: right;
    padding:50px 40px 25px 0;
    list-style: none;



    /* identical to box height */



    }
    #navli{
    padding:0 15px 0  0;
    display: inline;
    text-decoration: none;
    font-family: Steiner;
    font-style: normal;
    font-weight: thin;
    font-size: 26px;
    line-height: 31px;
    color: #F2F2F2;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }
    Link{
        text-decoration: none;
    }
    a{
        color:inherit;

    }


    li :hover{
        color: black;
    }
}

@media screen and (min-width: 1200px){

    nav{
        background-color: #595959;
        

    }
    .logo{
        width: 375px;
        height:110.42px;
        padding: 10px 0 0 70px;
        Margin-left: 70px;
    }
    .links{ 
    float: right;
    padding:50px 40px 25px 0;
    list-style: none;



    /* identical to box height */



    }
    #navli{
    padding:0 15px 0  0;
    display: inline;
    text-decoration: none;
    font-family: Steiner;
    font-style: normal;
    font-weight: thin;
    font-size: 26px;
    line-height: 31px;
    color: #F2F2F2;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }
    Link{
        text-decoration: none;
    }
    a{
        color:inherit;

    }


    li :hover{
        color: black;
    }
}