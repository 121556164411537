@media  screen 
    and (min-width: 375px) and (max-width: 613.99px)
    {
        .infoBar{
            padding-top: 0;
            padding-left: 0;
            display:none;
            
            
        }
        #statments{
            padding-top:30px;
            font-family: Arial, Helvetica, sans-serif;
            font-size: 18pt;
            font-weight:lighter;
            color: rgb(164, 10, 10);
        }
        .address{
            width: 100%;
            height: fit-content;
            background-color: white;
            margin-top: 100px;
            text-align: center;
         
         }
         #white{
            font-size: 14pt;
            font-family: Arial, Helvetica, sans-serif;
            font-weight: lighter;
        }
        #redTitle{
            color:rgb(164, 10, 10);
            font-size: 16pt;
            font-family: Arial, Helvetica, sans-serif;
            font-weight: lighter;
            
        }
        .infoBarTele{
            padding-top:80px;
            padding-left: 20px;
           

        }
        .infoBarEmail{
          
            padding-left: 20px;
            
           
        }
        .infoBarHours{
          
            padding-left: 20px;
        }
    
    #white{
        font-size: 14pt;
        font-family: Arial, Helvetica, sans-serif;
        font-weight: lighter;
        
    }
    }

@media  screen 
    and (min-width: 614px) and (max-width: 780px)
    {
        .infoBar{
            padding-top: 0;
            padding-left: 0;
            display:none;
            
            
        }
        #statments{
            padding-top:30px;
            font-family: Arial, Helvetica, sans-serif;
            font-size: 18pt;
            font-weight:lighter;
            color: rgb(164, 10, 10);
        }
        .address{
            width: 100%;
            height: fit-content;
            background-color: white;
            margin-top: 250px;
            text-align: center;
         
         }
         #white{
            font-size: 14pt;
            font-family: Arial, Helvetica, sans-serif;
            font-weight: lighter;
        }
        #redTitle{
            color:rgb(164, 10, 10);
            font-size: 16pt;
            font-family: Arial, Helvetica, sans-serif;
            font-weight: lighter;
            
        }
        .infoBarTele{
            float: left;
            padding-left: 15px;
            padding-top: 80px;

        }
        .infoBarEmail{
            padding-left: 50px;
            float: left;
            text-align: center;
            padding-top: 80px;
        }
        .infoBarHours{
            float: left;
            padding-left: 50px;
            padding-top: 80px;

        }
    
    #white{
        font-size: 14pt;
        font-family: Arial, Helvetica, sans-serif;
        font-weight: lighter;
        
    }
    }

@media  screen 
    and (min-width: 780px) and (max-width: 936px)
    {
        .infoBar{
            padding-top: 0;
            padding-left: 0;
            display:none;
            
            
        }
        #statments{
            padding-top:30px;
            font-family: Arial, Helvetica, sans-serif;
            font-size: 18pt;
            font-weight:lighter;
            color: rgb(164, 10, 10);
        }
        .address{
            width: 100%;
            height: fit-content;
            background-color: white;
            margin-top: 250px;
            text-align: center;
         
         }
         #white{
            font-size: 14pt;
            font-family: Arial, Helvetica, sans-serif;
            font-weight: lighter;
        }
        #redTitle{
            color:rgb(164, 10, 10);
            font-size: 16pt;
            font-family: Arial, Helvetica, sans-serif;
            font-weight: lighter;
          
        }
        .infoBarTele{
            float: left;
            padding-left: 90px;
            padding-top: 80px;

        }
        .infoBarEmail{
            padding-left: 90px;
            float: left;
            text-align: center;
            padding-top: 80px;
        }
        .infoBarHours{
            float: left;
            padding-left: 90px;
            padding-top: 80px;

        }
   
  
    #white{
        font-size: 14pt;
        font-family: Arial, Helvetica, sans-serif;
        font-weight: lighter;
        
    }
    }
    @media  screen 
    and (min-width: 936px){
.infoBar{
    padding-top: 30px;
    padding-left:150px;
    
}
.infoBarEmail{
display: none;
}
.infoBarTele{
    display:none;
}
.infoBarHours{
    display:none;
}
#redTitle{
    color:rgb(164, 10, 10);
    font-size: 16pt;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: lighter;
}

#white{
    font-size: 14pt;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: lighter;
}
    
.address{
   width: 100%;
   height: fit-content;
   background-color: white;
   margin-top: 250px;
   text-align: center;

}

#addInfo{
    
    font-family: Arial, Helvetica, sans-serif ;
    font-size: 18pt;
    font-weight:lighter;
}
#statments{
    padding-top:30px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 18pt;
    font-weight:lighter;
    color: rgb(164, 10, 10);
    
}
    }