@media only screen 
    and (min-device-width: 360px) 
    and (min-device-height: 812px) 
    and (-webkit-device-pixel-ratio: 3) {
        .description{
            background-color: rgb(164, 10, 10);
            color:white;
        }
        .phone{
           
            padding-top: 5px;
            text-align: center;
            font-family: Steiner;
        }
        .email{
            text-align: center;
            padding-top: 5px;
            font-family: Steiner;
        }
        .hours{
            text-align: center;
            padding-top: 5x;
            font-family: Steiner;
        
        }
        .footp{
            font-weight: bold;
            margin-bottom: 2px;
            font-size: 3vw;
            text-align: center;
            
        }
        .email :hover{
            color: black;
        
        }
       
        
       
    
        .footh4{
            display: block;
            font-size: 4vw;
            margin-top: 1em;
            margin-bottom:5px;
            margin-left: 0;
            margin-right: 0;
            font-weight: bold;
        }
        a{
            color:inherit;
    
        }
        #numb{
            text-decoration: none;
        }
    #numb :hover{
      
        color:black;
    }
   

    
    }


@media screen and (min-width:1080px){
.description{
    background-color: rgb(164, 10, 10);
    color:white;
}
.phone{
   
    padding-top: 30px;
    text-align: center;
    font-family: Steiner;
}
.email{
    text-align: center;
    padding-top: 30px;
    font-family: Steiner;
    

}
.hours{
    text-align: center;
    padding-top: 30px;
    font-family: Steiner;

}
p{
    font-weight: bolder;
    margin-bottom: 2px;
    
}
.email :hover{
    color: black;

}
.info{
    text-align: center;
    /* padding-left:200px; */
}

footer{

    width: 100%;
    bottom:0;
    position: relative;
}

#numb{
    text-decoration: none;
}
#numb :hover{
    color:black;
}


}

@media screen and (min-width:550px) and (max-width: 1080px){
    .description{
        background-color: rgb(164, 10, 10);
        color:white;
    }
    .phone{
       
        padding-top: 15px;
        text-align: center;
        font-family: Steiner;
    }
    .email{
        text-align: center;
        padding-top: 15px;
        font-family: Steiner;
    }
    .hours{
        text-align: center;
        padding-top: 15px;
        font-family: Steiner;
    
    }
    .footp{
        font-weight: bold;
        margin-bottom: 2px;
        font-size: 1.5vw;
        text-align: center;
        
    }
    .email :hover{
        color: black;
    
    }
   
    
    footer{
    
        width: 100%;
        bottom:0;
        position: relative;
    }

    .footh4{
        display: block;
        font-size: 2vw;
        margin-top: 1em;
        margin-bottom:5px;
        margin-left: 0;
        margin-right: 0;
        font-weight: bold;
    }
    a{
        color:inherit;

    }
    #numb{
        text-decoration: none;
    }
    #numb :hover{
        color:black;
    }
   


}
@media screen and (max-width: 549px){
    .description{
        background-color: rgb(164, 10, 10);
        color:white;
    }
    .phone{
       
        padding-top: 15px;
        text-align: center;
        font-family: Steiner;
    }
    .email{
        text-align: center;
        padding-top: 15px;
        font-family: Steiner;
    }
    .hours{
        text-align: center;
        padding-top: 15px;
        font-family: Steiner;
    
    }
    .footp{
        font-weight: bold;
        margin-bottom: 2px;
        font-size: 10pt;
        text-align: center;
        
    }
    .email :hover{
        color: black;
    
    }
   
    
    footer{
    
        width: 100%;
        bottom:0;
        position: relative;
    }

    .footh4{
        display: block;
        font-size: 11pt;
        margin-top: 1em;
        margin-bottom:5px;
        margin-left: 0;
        margin-right: 0;
        font-weight: bold;
    }
    a{
        color:inherit;

    }
    #numb{
        text-decoration: none;
    }

    #numb :hover{
        color:black;
    }
   

}