
@media  only screen 
and (device-width: 375px) 
and (device-height: 812px)
and (-webkit-device-pixel-ratio: 3) {
    .shopHeader{
        margin-left:15px;
        font-family: Steiner;
        font-weight: bolder;
        font-size: 4vw;
        margin-top: 15px;
    }
    #OShr{
        margin-left: 15px;
        margin-right: 220px;
        border-width: 3px;
    }
    .shopInfo{
       
        font-family: 'Roboto', sans-serif;
        font-size: 2vw;
        float: center;
        position: relative;
    }
  
    .mainInfoOS{
        height: auto;
        width: 375px;
        position: relative;
        margin-bottom: 30vh;

    
    }
    .carousel{
        width: 375px; 
        height: 250px;
        float:center;
        position:relative;
    }
    #imgs{
        widows: 375px;; 
        height:250px;
        float: center;
    }


}
@media screen and (min-width: 360px)and (max-width:513px){
    .shopHeader{
        margin-left:15px;
        font-family: Steiner;
        font-weight: bolder;
        font-size: 4vw;
        margin-top: 15px;
    }
    #OShr{
        margin-left: 15px;
        margin-right: 220px;
        border-width: 3px;
    }
    .shopInfo{
       
        font-family: 'Roboto', sans-serif;
        font-size: 2vw;
        float: center;
        position: relative;
    }
  
    .mainInfoOS{
        height: auto;
        width: 375px;
        position: relative;
        margin-bottom: 45vh;

    
    }
    .carousel{
        width: 100%; 
        height: 250px;
        float:center;
        position:relative;
    }
    #imgs{
        widows: 375px;; 
        height:250px;
        float: center;
    }

}
@media screen and (min-width: 513px)and (max-width:810px){
    .shopHeader{
        margin-left:15px;
        font-family: Steiner;
        font-weight: bolder;
        font-size: 4vw;
        margin-top: 15px;
    }
    #OShr{
        margin-left: 15px;
        margin-right: 220px;
        border-width: 3px;
    }
    .shopInfo{
       
        font-family: 'Roboto', sans-serif;
        font-size: 2vw;
        float: left;
        position: relative;
    }
  
    .mainInfoOS{
        height: auto;
        width: 375px;
        position: relative;
        margin-bottom: 150px;

    
    }
    .carousel{
        width: 400px; 
        height: 300px;
        float:center;
        position:relative;
    }
    #imgs{
        widows: 375px;; 
        height:250px;
        float: center;
    }

}

@media screen and (min-width:810px) and (max-width:1049px){
    .shopHeader{
        margin-left:15px;
        font-family: Steiner;
        font-weight: bolder;
        font-size: 4vw;
        margin-top: 15px;
    }
    #OShr{
        margin-left: 15px;
        margin-right: 440px;
        border-width: 3px;
    }
    
    #imgs{
        widows: 400px;; 
        height:300px;
        float: center;
    }
    .mainInfoOS{
        width: auto;
        height: auto;
        position: relative;
    }
    .shopInfo{
        
      position:relative;

    }
   
    .carousel{
        margin-left:15px;
        width: 400px; 
        height: 300px;
        float:left;
        padding-right: 15px;
    }
    }

@media screen and (min-width:1050px){
.shopHeader{
    margin-left:15px;
    font-family: Steiner;
    font-weight: bolder;
    font-size: 28pt;
    margin-top: 15px;
}
#OShr{
    margin-left: 15px;
    margin-right: 440px;
    border-width: 3px;
}
.shopInfo{
    float:left;
    font-size: 20pt;
    margin-right:300px;
    margin-left:30px;
}
.mainInfoOS{
    width: auto;
    /* height: 74.5v; */

}



}
/* @media screen and (min-height:800px){
    .shopHeader{
        margin-left:15px;
        font-family: Steiner;
        font-weight: bolder;
        font-size: 28pt;
        margin-top: 15px;
    }
    #OShr{
        margin-left: 15px;
        margin-right: 440px;
        border-width: 3px;
    }
    .shopInfo{
        float:left;
        margin-left:15px;
        margin-top: 15px;
        font-family: 'Roboto', sans-serif;
        font-size: 20pt;
        
    }
    
    .mainInfoOS{
        width: auto;
        height: 74.5vh;
    }
   
   
}

@media screen and (max-height:800px){
    .shopHeader{
        margin-left:15px;
        font-family: Steiner;
        font-weight: bolder;
        font-size: 28pt;
        margin-top: 15px;
    }
    #OShr{
        margin-left: 15px;
        margin-right: 440px;
        border-width: 3px;
    }
    .shopInfo{
      
        margin-left: 15px;
        font-family: 'Roboto', sans-serif;
        font-size: 20pt;
        text-align: Left;
        
    }
    
    .mainInfoOS{
        width: auto;
        height: 74.5vh;
    }

        

} */
