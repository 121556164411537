@media screen 
    and (min-device-width: 360px) 
    and (max-device-width: 500px)
    {
        .mainSection{

            background: url(../../assets/homebackground.jpg) no-repeat center ;
            height:585px;
        
        }
        
        .bio{
        
            padding-top: 140px;
           text-align: center;
           color:white;
           font-family: Arial, Helvetica, sans-serif;
           font-size:8vw;
        }
        .genServ{
            margin-left: 30px;
            margin-right: 30px;
            text-align: center;
            color:white;
            font-family: Arial, Helvetica, sans-serif;
            font-size: 4vw;
        }
        
        
    }
    
    
    @media screen and (min-width:768px) and (max-width:992px){
        .mainSection{
    
        background: url(../../assets/homebackground.jpg) no-repeat center ;
       
        
        margin: 0 50px 0 50px;
    
      
        height: auto;
    
        }
        
        .bio{
        
            padding-top: 140px;
           text-align: center;
           color:white;
           font-family: Arial, Helvetica, sans-serif;
           font-size:5vw;
        }
        .genServ{
            margin-left: 140px;
            margin-right: 140px;
            text-align: center;
            color:white;
            font-family: Arial, Helvetica, sans-serif;
            font-size: 2vw;
        }
    
    
    }
    
    
    
    @media screen and (min-width:992px) and (max-width:1200px){
    .mainSection{
    
        background: url(../../assets/homebackground.jpg) no-repeat center ;
       
        
        margin: 0 10px 0 10px;
    
      
        height: 650px;
    
    }
    
    .bio{
    
        padding-top: 140px;
       text-align: center;
       color:white;
       font-family: Arial, Helvetica, sans-serif;
       font-size:5vw;
    }
    .genServ{
        margin-left: 140px;
        margin-right: 140px;
        text-align: center;
        color:white;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 2vw;
    }

    }
@media screen and (min-width:501px) and (max-width: 768px){
    .mainSection{

        background: url(../../assets/homebackground.jpg) no-repeat center ;
       
        
        margin: 0 10px 0 10px;
    
      
        height: 74.5vh
        ;
    
    }
    
    .bio{
    
        padding-top: 140px;
       text-align: center;
       color:white;
       font-family: Arial, Helvetica, sans-serif;
       font-size:5vw;
    }
    .genServ{
        margin-left: 140px;
        margin-right: 140px;
        text-align: center;
        color:white;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 2vw;
    }
    
    
}


@media screen and (min-width:768px) and (max-width:992px){
    .mainSection{

    background: url(../../assets/homebackground.jpg) no-repeat center ;
   
    
    margin: 0 50px 0 50px;

  
    height: 650px;
    
    }
    
    .bio{
    
        padding-top: 140px;
       text-align: center;
       color:white;
       font-family: Arial, Helvetica, sans-serif;
       font-size:5vw;
    }
    .genServ{
        margin-left: 140px;
        margin-right: 140px;
        text-align: center;
        color:white;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 2vw;
    }


}



@media screen and (min-width:992px) and (max-width:1300px){
.mainSection{

    background: url(../../assets/homebackground.jpg) no-repeat center ;
   
    
    margin: 0 50px 0 50px;

  
    height: 650px;

}

.bio{

    padding-top: 140px;
   text-align: center;
   color:white;
   font-family: Arial, Helvetica, sans-serif;
   font-size:5vw;
}
.genServ{
    margin-left: 140px;
    margin-right: 140px;
    text-align: center;
    color:white;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 2vw;
}
}

@media screen and (min-width:1300px){
    .mainSection{

        background: url(../../assets/homebackground4.jpg) no-repeat center ;
   
    
        margin: 0 50px 0 50px;
    
      
        height: 74.5vh;
    
    }
    
    .bio{
    
        padding-top: 140px;
       text-align: center;
       color:white;
       font-family: Arial, Helvetica, sans-serif;
       font-size: 55pt;
    }
    .genServ{
        margin-left: 140px;
        margin-right: 140px;
        text-align: center;
        color:white;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 16pt;
       
    }
    
}
@media screen and (min-height: 860px){
    .mainSection{

        background: url(../../assets/homebackground5.jpg) no-repeat center ;
       
        
        margin: 0 50px 0 50px;
    
      
        height: 80vh;
    
    }


}