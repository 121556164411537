@media screen and (min-width: 550px) and (max-width: 814px){
    .button2{
        margin-left: 30px;
    }
    .button4{
        margin-left: 15px;
        margin-bottom: 20px;
    }
    .button1{
        margin-left:30px;
    }
    .button3{
        margin-left:15px;
    }
    .photo1{
        width:200px;
        border-style: solid;
        border-color: black;
    }
    
    .photo2{
        width: 270px;
        border-style: solid;
        border-width: 3px;;
        border-color: black;
    }
    
    .photo3{
        width: 270px;
        border-style: solid;
        border-width: 3px;;
        border-color: black;
    }
    
    .photo4{
        width: 270px;
        border-style: solid;
        border-width: 3px;;
        border-color: black;
    }
}

@media screen and (min-width: 814px ) and (max-width: 1070px){
    .button2{
        margin-left: 30px;
    }
    .button4{
        margin-left: 15px;
        margin-bottom: 20px;
    }
    .button1{
        margin-left:30px;
    }
    .button3{
        margin-left:15px;
    }
    .photo1{
        width:270px;
        border-style: solid;
        border-color: black;
    }
    
    .photo2{
        width: 350px;
        border-style: solid;
        border-width: 3px;;
        border-color: black;
    }
    
    .photo3{
        width: 350px;
        border-style: solid;
        border-width: 3px;;
        border-color: black;
    }
    
    .photo4{
        width: 350px;
        border-style: solid;
        border-width: 3px;;
        border-color: black;
    }
}

@media screen and (min-width: 1070px)and (max-width:1426px){
    .button4{
        margin-left:30px;
    }
    .button1{
        margin-left:30px;
    
    }
    .button2{
        margin-left:15px;
    }
    .button3{
        margin-left:15px;
    }
    .photo1{
        width:270px;
        border-style: solid;
        border-color: black;
    }
    
    .photo2{
        width: 350px;
        border-style: solid;
        border-width: 3px;;
        border-color: black;
    }
    
    .photo3{
        width: 350px;
        border-style: solid;
        border-width: 3px;;
        border-color: black;
    }
    
    .photo4{
        width: 350px;
        border-style: solid;
        border-width: 3px;;
        border-color: black;
    }
}


@media screen and (min-width: 1426px){
.photo1{
    width:270px;
    border-style: solid;
    border-color: black;
}

.photo2{
    width: 350px;
    border-style: solid;
    border-width: 3px;;
    border-color: black;
}

.photo3{
    width: 350px;
    border-style: solid;
    border-width: 3px;;
    border-color: black;
}

.photo4{
    width: 350px;
    border-style: solid;
    border-width: 3px;;
    border-color: black;
}
.button1{
    margin-left:30px;
    margin-right:15px;
}
.button2{
    
    margin-right:15px;
}
.button3{
    
    margin-right:15px;
    margin-bottom: 20px;
}
.button4{
    
    margin-right:15px;
}
}
