@media screen 
    and (min-width:360px) and (max-width: 499.99px)
    {
        form{
        font-family:Arial, Helvetica, sans-serif;
        font-size: 14pt;
        float: none;
        width: 100%;
        padding-left:15px;
      
    }
    button{
        margin-top:15px;
        color:rgb(164, 10, 10);
    }
    
     .form-control :hover{
        background-color: grey;
        
    }
    
    }
@media screen 
    and (min-width:500px) and (max-width: 699.99px)
    {
        form{
        font-family:Arial, Helvetica, sans-serif;
        font-size: 14pt;
        float: none;
        width: 70%;
        padding-left:15px;
      
    }
    button{
        margin-top:15px;
        color:rgb(164, 10, 10);
    }
    
     .form-control :hover{
        background-color: grey;
        
    }
    
    }

@media screen 
    and (min-width:700px) and (max-width: 935.99px)
    {
        form{
        font-family:Arial, Helvetica, sans-serif;
        font-size: 14pt;
        float: none;
        width: 60%;
        padding-left:15px;
      
    }
    button{
        margin-top:15px;
        color:rgb(164, 10, 10);
    }
    
     .form-control :hover{
        background-color: grey;
        
    }
    
    }
    @media screen 
    and (min-width: 936px){

form{
    font-family:Arial, Helvetica, sans-serif;
    font-size: 14pt;
    float: right;
    width:40%;
    margin-right: 200px;
    padding-top: 30px;
}
button{
    margin-top:15px;
    color:rgb(164, 10, 10);
}

 .form-control :hover{
    background-color: grey;
    
}

    }