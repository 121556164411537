@media screen and (min-width: 1081px){
  .social i
  {
    padding-right: 10px;
    font-size: 30pt;
    color: white;
  }
  .social i:hover
  {
    color: black;
  }
}
  @media screen and (min-width:550px) and (max-width: 1080px){
    .social i{
      padding-right: 10px;
      font-size: 3vw;
      color: white;
    }
    .social i:hover
  {
    color: black;
  }
  }

  @media screen and (min-width: 360px) and (max-width: 549px){
    .social i
    {
      
      padding-right: 10px;
      font-size: 20pt;
      color: white;
    }
    .social i:hover
    {
      color: black;
    }
  }